.link {
  color: rgba(255, 255, 255, 0.75);
}

.link:hover {
  color: rgba(255, 255, 255, 0.5);
}

.map-container {
  height: 350px;
}

@media (max-width: 499px) {
  .map-container {
    height: 200px;
  }
}

.map {
  width: auto;
  height: 100%;
  background-size: cover;
  background-position-y: -100px;
}
