#disclaimer .container {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#disclaimer p {
  font-size: 12px;
}
